<template>
  <div class="white">
    <v-alert
      type="info"
      icon="mdi-checkbox-multiple-marked-outline"
      border="left"
      class="my-0"
      tile
    >
      <h4 class="text-center">Five (5) Weeks Digital Skill Training</h4>
    </v-alert>
    <!-- <v-col cols="12" class="black--text text-center" v-if="applicationClosed">
        <p><b>The application has closed.</b></p>
        <p>If you are interested in the digital skill training, kindly visit our office:</p>
        <p>
          Top Floor, 68B Christore Building, <br />Opp. Crunchies Restaurant,<br />
          Similoluwa, Ado Ekiti, Ekiti State, Nigeria.<br />
          Phone: +2348071572767</p>
      </v-col>      -->
    <v-container v-if="!application_no">
      <h5 class="text-center mb-2">
        <b>Class starts Mon July 31st</b><br />
        <b class="error--text">All fields are required.</b>
      </h5>
      <v-form ref="dform" lazy-validation @submit.prevent>
        <v-row class="mt-2">
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="dform.fullname"
              label="Full Name:"
              :rules="nameRules"
              outlined
              dense
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model.trim="dform.phone"
              maxlength="15"
              type="number"
              :rules="phoneRules"
              label="Mobile Number:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              :rules="emailRules"
              v-model.trim="dform.email"
              label="E-mail:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.gender"
              :items="genders"
              :rules="messageRules"
              label="Gender:"
              outlined
              dense
              required
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model.trim="dform.dob"
              :rules="messageRules"
              label="Date of Birth:"
              type="date"
              required
              outlined
              dense
              :max="maxDate"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-textarea
              v-model="dform.residentAddress"
              :rules="messageRules"
              label="Residential Address:"
              outlined
              dense
              auto-grow
              rows="1"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.qualification"
              :rules="messageRules"
              label="Highest Educational Qualification:"
              outlined
              dense
              :items="qualifications"
              @change="
                dform.qualification == 'SSCE' ? (dform.discipline = 'SSCE') : ''
              "
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="4" v-if="showDiscipline">
            <v-text-field
              v-model="dform.discipline"
              :rules="messageRules"
              label="Course of Study:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.religion"
              :rules="messageRules"
              :items="religions"
              label="Religion:"
              outlined
              dense
              required
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-radio-group v-model="dform.laptop" :rules="messageRules" row>
              <span>Do you have a laptop?</span>
              <v-radio
                label="Yes"
                color="success"
                value="Yes"
                class="mx-2"
              ></v-radio>
              <v-radio label="No" color="red" value="No" class="mx-2"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.experience"
              :rules="messageRules"
              label="Experience With Computer:"
              outlined
              dense
              :items="experienceLevel"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.program"
              :rules="messageRules"
              label="Program:"
              outlined
              dense
              :items="availablePrograms"
              item-text="title"
              item-value="title"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.trainingCenter"
              :rules="messageRules"
              label="Preferred Training Center:"
              outlined
              dense
              :items="trainingCenters"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="dform.nextKin"
              :rules="messageRules"
              label="Next of Kin/Parent Full Name:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="dform.nextKinPhone"
              :rules="messageRules"
              label="Next of Kin/Parent Phone Number:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="dform.nextKinAddress"
              :rules="messageRules"
              label="Next of Kin/Parent Address:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lang="12">
            <v-textarea
              v-model="dform.bio"
              :rules="messageRules"
              label="Tell us about yourself:"
              hint="Please include any health challenge"
              persistent-hint
              outlined
              dense
              auto-grow
              rows="1"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>

      <v-row justify="center" class="pa-5">
        <v-spacer></v-spacer>

        <v-btn
          tile
          color="success"
          :loading="loading"
          @click="submitApplication"
          :disabled="!isValid"
        >
          Submit Application
        </v-btn>
      </v-row>
    </v-container>

    <v-row class="ma-1 px-2 px-sm-1" v-else>
      <v-col cols="12">
        <p>
          You have successfully submmited your application. Your application
          number is
          <b>{{ application_no }}</b
          >. Keep your application number safe for further use.
        </p>
        <h5>You can join the appropriate Kinplus WhatsApp Group below:</h5>
        <p>
          <a href="https://chat.whatsapp.com/EdC2jL9XJ5cLSBZk4RU4Hn"
            >Ido-Ekiti</a
          >
        </p>
        <p>
          <a href="https://chat.whatsapp.com/B9JJrZ3UA5P0MRxtqKzfYk"
            >Ikole-Ekiti</a
          >
        </p>
        <p>
          <a href="https://chat.whatsapp.com/CGjJRmodVWG2Jv7pLV9sAa"
            >Federal University Oye (FUOYE), Ikole Campus</a
          >
        </p>
        <p>
          <a href="https://chat.whatsapp.com/GYEUlwV5Q7qEgfHwrVbh0d"
            >Ekiti State University (EKSU)</a
          >
        </p>
        <p>
          <a href="https://chat.whatsapp.com/GW1NVU8Xq8DClmwosdSJQG"
            >General Kinplus Community</a
          >
        </p>
        <v-btn @click="initializePage" color="primary">close</v-btn>
      </v-col>
    </v-row>
    <!-- </v-alert> -->

    <v-snackbar v-model="snackbar" :color="snackColor" :timeout="4000">
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-alert
      type="info"
      icon="mdi-checkbox-multiple-marked-outline"
      border="left"
      class="my-0"
      tile
    >
      <h4 class="text-center"><b>Sponsor: Akintunde Oyebode</b></h4>
    </v-alert>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, rulesMixin } from "@/mixins";
import { mapState } from "vuex";
export default {
  name: "SkillUp",
  mixins: [snackMixin, rulesMixin],
  data() {
    return {
      dialog: false,
      disabled: false,
      show: true,
      applicationClosed: false,
      application_no: "",
      errorCounter: 0,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      availablePrograms: [
        {
          title: "Website DEVELOPMENT (Coding)",
          subtitle:
            "Immerse yourself in the core principles of software development and learn the necessary programming skills required for Web, Mobile or Desktop Application Development while working on projects.",
        },
        {
          title: "GRAPHIC DESIGN",
          subtitle:
            "Learn how to determine  objectives for decision-making, through creativity, innovation and lateral thinking along with manual or digital tools, transforming them for proper interpretation.",
        },
        {
          title: "DATA PROCESSING",
          subtitle:
            "Learn the process of working with word documents, spreadsheet, presentation, and images.",
        },
      ],
      trainingCenters: [
        "Ado-Ekiti",
        "Ido-Ekiti",
        "Ikole-Ekiti",
        "Virtual/Online",
      ],
      qualifications: [
        "Bachelor",
        "HND",
        "ND",
        "NCE",
        "SSCE",
        "SSS Class",
        "JSS Class",
      ],
      dform: this.initializeForm(),
    };
  },
  computed: {
    ...mapState(["genders", "religions", "experienceLevel"]),
    showDiscipline() {
      return (
        this.dform.qualification == "Bachelor" ||
        this.dform.qualification == "HND" ||
        this.dform.qualification == "ND" ||
        this.dform.qualification == "NCE"
      );
    },
    isValid() {
      const reqf = [
        "fullname",
        "bio",
        "phone",
        "email",
        "gender",
        "dob",
        "residentAddress",
        "qualification",
        "religion",
        "trainingCenter",
        "laptop",
        "program",
      ];
      return reqf.every((v) => !!this.dform[v]);
    },
    maxDate() {
      let now = new Date();
      let targetDate = new Date(new Date().setFullYear(now.getFullYear() - 6));
      return targetDate.toISOString().substring(0, 10);
    },
  },
  methods: {
    closeForm() {
      this.dform = this.initializeForm();
    },
    initializePage() {
      this.dform = this.initializeForm();
      this.application_no = "";
    },
    submitApplication() {
      if (!this.dform.email) {
        this.displayMsg("All fields are required!", "error");
        return;
      }
      if (!this.dform.program) {
        this.displayMsg(
          "Please re-select the program you are applying for",
          "error"
        );
        return;
      }
      this.loading = true;
      apiClient
        .post("/skillup", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.application_no = res.data.application_no;
            this.displayMsg("Application submitted Succesfully. ");
            this.closeForm();
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((error) => {
          this.displayMsg(error.message, "error");
          this.closeForm();
        });
    },

    saveChanges() {
      this.loading = true;
      apiClient
        .put("/skillup", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes Saved successfully");
            this.closeForm();
          }
        })
        .catch((error) => {
          this.displayMsg(error.message, "error");
        });
    },
    initializeForm() {
      return {
        program: "",
        fullname: "",
        phone: "",
        email: "",
        gender: "",
        dob: "",
        residentAddress: "",
        qualification: "",
        discipline: "",
        religion: "",
        trainingCenter: "",
        laptop: "",
        bio: "",
      };
    },
  },
};
</script>
